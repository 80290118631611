
import tableList from "@/components/renderComponents/tableList/tableList.vue";
import Upload from "@/components/renderComponents/upload/Upload.vue";
import download from "@/common/ts/downFile";
import {
  defineComponent,
  getCurrentInstance,
  onMounted,
  reactive,
  toRefs,
} from "vue";
import { mainServer, downServer } from "@/apiServer/installServer";
import { ElMessageBox, ElMessage } from "element-plus";
import axios from "axios";
export default defineComponent({
  name: "AppContent",
  components: {
    tableList,
    Upload,
  },
  setup() {
    const { proxy }: any = getCurrentInstance();
    let data: any = reactive({
      IsCollapse: true,
      tableListFalg: false,
      keyword: "",
      isShowAddApp: false,
      listData: {
        per_page: 10,
        current_page: 1,
        system_app_id: "",
        total: 0,
        data: [],
        keyWord: "",
        config: [
          {
            label: "文件名",
            att: "system_app_content_name",
            width: "200",
            type: "string",
          },
          {
            label: "字节",
            att: "system_app_content_size",
            width: "/",
            type: "string",
          },
          {
            label: "类型",
            att: "system_app_content_type",
            width: "/",
            type: "string",
          },
          {
            label: "操作",
            att: "operation",
            width: "220",
            type: "button",
          },
        ],
      },
    });
    const FnGetSettingApi = function () {
      const params = {};
      mainServer.SetConfigApi(params, true).then((res: any) => {
        console.log("FnGetSettingApi: ConfigInfo ====>", res.data.data.res);
      });
    };
    function FnChangeTableListModel(falg: Boolean) {
      data.tableListFalg = falg;
      console.log(
        "FnChangeTableListModel: data.tableListFalg",
        data.tableListFalg
      );
    }
    function FnHandleCloseForAddApp() {
      FnGetapp();
      data.isShowAddApp = false;
    }
    function FnCurrentChange(e: any) {
      data.listData.current_page = e;
      FnGetapp();
    }
    function FnRowDelect(item: any) {
      console.log("FnRowDelect: item ---->", item);
    }
    function FnRowEdit(item: any) {
      console.log("FnRowEdit: item ---->", item);
      // let a = document.createElement("a");
      // a.href = item.allUrl;
      // a.setAttribute("download", "chart-download");
      // a.click();
      FnGetFileStream(item);
    }
    function FnCopyAddress(item: any) {
      handleCopyValue(item.allUrl)
        .then(() => {
          ElMessage({
            message: "复制成功",
            type: "success",
          });
        })
        .catch(() => {
          ElMessage({
            message: "复制失败",
            type: "success",
          });
        });
      // navigator.clipboard.writeText(item.allUrl).then(() => {
      //   ElMessage({
      //     message: "复制成功",
      //     type: "success",
      //   });
      // });
    }
    async function FnGetFileStream(item: any) {
      const params = {
        system_app_content_id: item.system_app_content_id,
      };
      const res = await downServer.GetFileStream(params);
      download(res.data, item.system_app_content_name, res.data.type);
    }
    // async function FnTestDown() {
    //   const res = await downServer.TestDown({});
    //   download(res.data, '测试' , res.data.type);
    // }
    function handleCopyValue(text: string) {
      //浏览器禁用了非安全域的 navigator.clipboard 对象
      //在线上环境会报错 TypeError: Cannot read properties of undefined (reading 'writeText')
      if (!navigator.clipboard && window.isSecureContext) {
        return (navigator as any).clipboard.writeText(text);
      } else {
        // 判断是否支持拷贝
        if (!document.execCommand("copy")) return Promise.reject();
        // 创建标签，并隐藏
        const textArea = document.createElement("textarea");
        textArea.style.position = "fixed";
        textArea.style.top = textArea.style.left = "-100vh";
        textArea.style.opacity = "0";
        textArea.value = text;
        document.body.appendChild(textArea);
        // 聚焦、复制
        textArea.focus();
        textArea.select();
        return new Promise((resolve: any, reject) => {
          // 不知为何，必须写这个三目，不然copy不上
          document.execCommand("copy") ? resolve() : reject();
          textArea.remove();
        });
      }
    }
    async function FnGetapp() {
      const res = await mainServer.GetAppContent(data.listData, false);
      console.log("FnGetapp: res ---->", res);
      if (res.data.stateMsg === "success") {
        data.listData.data = res.data.data.res.data.map((val: any) => {
          val.operation = [
            {
              name: "删除",
              type: "error",
              fn: FnRowDelect,
            },
            {
              name: "下载",
              type: "primary",
              fn: FnRowEdit,
            },
            {
              name: "复制地址",
              fn: FnCopyAddress,
            },
          ];
          return val;
        });
        data.listData.total = res.data.data.res.total;
      } else {
        proxy.$CommonFun.FnCommonMsg(res.data.data.res, "error");
      }
    }
    onMounted(() => {
      data.listData.system_app_id = proxy.routeStore.system_app_id;
      FnGetapp();
      // FnTestDown();
    });
    return {
      ...toRefs(data),
      proxy,
      FnChangeTableListModel,
      FnCurrentChange,
      FnGetapp,
      FnHandleCloseForAddApp
    };
  },
});
