
import {
  defineComponent,
  getCurrentInstance,
  onBeforeUnmount,
  onMounted,
  reactive,
  toRefs,
  ref,
  computed,
} from "vue";
import { useRouter, useRoute } from "vue-router"; //引入路由
import { mainServer } from "@/apiServer/installServer";
import commonFun from "@/common/ts/commonFun";
export default defineComponent({
  name: "Upload",
  props: {
    // viewModule: {
    //   type: Boolean,
    //   default: false,
    // }, // true 为展示
    // listData: {
    //   type: Object,
    // },
    system_app_id: {
      type: String,
    },
  },
  setup(props, context) {
    const { proxy }: any = getCurrentInstance();
    const router = useRouter();
    const route = useRoute();
    let data = reactive({
      path: '',
      paramsData: {},
      paramsHeaders:{}
    });

    onMounted(() => {
      console.log('proxy.vuexUserInfo.system_user_info_nickname' , proxy);
      data.path = `${proxy.vuexServePath.serviceBus}/upload/api_1`,
      data.paramsHeaders = {
        token: proxy.vuexUserInfo.system_user_info_token,
        encryption: false,
        secret: 0,
      }
      data.paramsData = {
        system_app_password: proxy.routeStore.system_app_password
      }
    });
    onBeforeUnmount(() => {});
    return {
      ...toRefs(data),
      proxy,
    };
  },
});
