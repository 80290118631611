/*
 * @Author: alanChen
 * @Date: 2022-01-16 11:05:52
 * @LastEditTime: 2022-01-17 09:46:15
 * @LastEditors: your name
 * @Description: 下载文件
 * @FilePath: \project\src\common\ts\downFile.ts
 * tel:15584312340/mail:15584312340@163.com

/**
 * 保存
 * @param  {Blob} blob
 * @param  {String} filename 想要保存的文件名称
 */
function saveAs(blobs: any, filename: string, type: string) {
  const blob: any = new Blob([blobs], {
    type: type,
  });
  // 兼容不同浏览器的URL对象
  const url = window.URL || window.webkitURL || (window as any).moxURL;
  // 创建下载链接
  const downloadHref = url.createObjectURL(blob);
  // 创建a标签并为其添加属性
  let downloadLink = document.createElement("a");
  downloadLink.href = downloadHref;
  console.log(downloadHref);
  downloadLink.download = filename;
  // 触发点击事件执行下载
  downloadLink.click();
  window.URL.revokeObjectURL(downloadHref); //下载完成进行释放
}

/**
 * 下载
 * @param  {String} url 目标文件地址
 * @param  {String} filename 想要保存的文件名称
 */
function download(res: string, filename: string, type: string) {
  saveAs(res, filename, type);
}

export default download;
